@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap);
.App{padding:0;margin:0;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;min-height:100vh;width:100vw;overflow:scroll;font-family:"Nunito Sans","Trebuchet MS",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}*{scrollbar-width:none;box-sizing:border-box}* ::-webkit-scrollbar{width:0;height:0;-ms-overflow-style:none}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}p,span,label,li,h1,h2,h3,h4,h5,h6{color:#ebebeb}
.Stepper_stepper__BvRiS{display:-webkit-flex;display:flex;-webkit-justify-content:space-evenly;justify-content:space-evenly;width:100%;max-width:500px}.Stepper_stepper__BvRiS .Stepper_step__1k0C7{width:40px;height:40px;border-radius:50%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;border:2px solid #ebebeb}.Stepper_stepper__BvRiS .Stepper_step__1k0C7 .Stepper_stepText__31FVM{cursor:default}.Stepper_stepper__BvRiS .Stepper_selected__NiRZ2,.Stepper_stepper__BvRiS .Stepper_completed__m3JCM{background:#ebebeb}.Stepper_stepper__BvRiS .Stepper_selected__NiRZ2 .Stepper_stepText__31FVM,.Stepper_stepper__BvRiS .Stepper_completed__m3JCM .Stepper_stepText__31FVM{display:-webkit-flex;display:flex;color:#141414}
.Journey_journeyContainer__MXquS{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;height:100%;padding-bottom:15px}.Journey_journeyContainer__MXquS .Journey_nameText__2Z2Gx{text-align:center;max-width:85%;font-size:1.3rem}.Journey_journeyContainer__MXquS .Journey_childrenContainer__3bRtK{-webkit-flex:1 1;flex:1 1;width:100%;max-width:750px;padding:15px 0}
.Button_button__2Lf63{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;max-width:200px;max-height:60px;margin:1rem;padding:1rem 1.5rem;color:#ebebeb;font-weight:500;font-size:1.1rem;border-radius:15px;background:none;border:3px solid #ebebeb;text-decoration:none;white-space:nowrap;position:relative;cursor:pointer}.Button_button__2Lf63 .Button_label__2AAHL{width:100%}.Button_button__2Lf63:hover{border:3px solid #d2d2d2;color:#454545;box-shadow:0px 0px 4px #ebebeb;-webkit-transform:scale(1.02);transform:scale(1.02)}.Button_button__2Lf63:active{border:3px solid #d2d2d2;color:#454545;box-shadow:0px 0px 6px #ebebeb}.Button_button__2Lf63:disabled{background:gray;border:3px solid gray;color:#d3d3d3;cursor:not-allowed}.Button_button__2Lf63 .Button_rightIcon__X_ONZ{margin-left:5px}
.IngredientField_ingredientFieldWrapper__2ofCJ{display:grid;grid-template-columns:60px 125px 1fr 25px;grid-gap:10px;gap:10px;border:2px solid #374243;border-radius:5px;margin:10px;padding:0 5px;max-width:450px;height:55px;width:100%}.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_numberInputContainer__249A0,.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_unitSelect__3Fphh{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_numberInputContainer__249A0 .IngredientField_numberInput__1ugrO{width:100%;height:38px;text-align:center}.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_unitSelect__3Fphh{max-width:125px}.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_unitSelect__3Fphh>div{width:100%}.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_ingredientText__3_Uon{max-height:45px;-webkit-align-self:center;align-self:center;text-transform:capitalize;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;display:-webkit-box;-webkit-box-orient:vertical}.IngredientField_ingredientFieldWrapper__2ofCJ .IngredientField_removeButton__1inaW{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;color:#ebebeb}
.AddIngredients_inputArea__3fVRR{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;height:75%;width:100%;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:unset;justify-content:unset}.AddIngredients_inputArea__3fVRR>.AddIngredients_ingredientFields__3Nado{display:grid;justify-items:center}.AddIngredients_buttonArea__bj5pb{height:25%;width:100%;display:grid;grid-template-columns:repeat(2, 1fr);direction:rtl}.AddIngredients_buttonArea__bj5pb>*{justify-self:center;width:100%}
.Field_field__2y5l0{margin:.313rem;max-width:300px}.Field_field__2y5l0 .Field_name__11eXN{display:block;transition:opacity .2s,visibility .2s}.Field_field__2y5l0 .Field_nameHidden__24XsG{visibility:hidden;opacity:0}.Field_field__2y5l0 .Field_inputWrapper__zb1sw{display:-webkit-flex;display:flex;position:relative;-webkit-align-items:center;align-items:center;width:18.75rem}.Field_field__2y5l0 .Field_inputWrapper__zb1sw .Field_input__11yvT{padding:.313rem;font-size:1.2rem;width:100%}.Field_field__2y5l0 .Field_inputWrapper__zb1sw .Field_rightIcon__1yxOP{position:absolute;right:0;-webkit-transform:translate(-0.5rem, 0);transform:translate(-0.5rem, 0);color:#374243;cursor:pointer}.Field_field__2y5l0 .Field_inputWrapper__zb1sw .Field_rightIcon__1yxOP>*{display:-webkit-flex;display:flex}.Field_field__2y5l0 .Field_inputWrapper__zb1sw .Field_rightIcon__1yxOP:hover{color:#596b6d}.Field_field__2y5l0 .Field_errorText__2TYl5{color:#e88c29;font-style:italic}
.AddTitle_inputArea__1izMt{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;height:75%;width:100%}.AddTitle_buttonArea__Oz3Dj{height:25%;width:100%;display:grid;grid-template-columns:repeat(2, 1fr);direction:rtl}.AddTitle_buttonArea__Oz3Dj>*{justify-self:center;width:100%}
.Confirmation_inputArea__3Iqxp{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;height:75%;width:100%}.Confirmation_buttonArea__1b2qK{height:25%;width:100%;display:grid;grid-template-columns:repeat(2, 1fr);direction:rtl}.Confirmation_buttonArea__1b2qK>*{justify-self:center;width:100%}
.CreateRecipe_createRecipe__1UZjN{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;height:100%;background-color:rgba(55,66,67,.25);border-radius:5px}.CreateRecipe_stageContainer__13BJ9{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-evenly;justify-content:space-evenly;height:100%;width:100%}
.Sidebar_sidebar__3qUkX{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.Sidebar_sidebar__3qUkX .Sidebar_heading__2fEX5{margin:0;color:#ebebeb;font-weight:600;text-align:center}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo{padding-left:.5rem;display:-webkit-flex;display:flex;-webkit-justify-content:space-evenly;justify-content:space-evenly;-webkit-flex-grow:1;flex-grow:1;list-style:none}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo .Sidebar_navItem__1NryS{display:inline-block;padding:10px;font-size:1.2rem;cursor:pointer;text-decoration:none;color:#ebebeb;width:70%}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo .Sidebar_navItem__1NryS:hover{color:#d2d2d2}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo .Sidebar_navItem__1NryS:active{color:#b8b8b8}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo .Sidebar_navItem__1NryS:hover:after{width:100%;background:#ebebeb}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo .Sidebar_navItem__1NryS:after{content:"";display:block;margin:auto;height:2px;width:0px;background:transparent;transition:width .4s ease,background-color .4s ease}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo .Sidebar_navItem__1NryS.Sidebar_active__9wFJE:after{content:"";display:block;margin:auto;height:2px;width:100%;background:#ebebeb}@media only screen and (min-width: 768px){.Sidebar_sidebar__3qUkX .Sidebar_heading__2fEX5{height:100px;margin-bottom:2.5rem;text-align:left}.Sidebar_sidebar__3qUkX .Sidebar_nav__18KQo{-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:flex-start;justify-content:flex-start}}
.Layout_layout__vps_j{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;height:100vh}.Layout_layout__vps_j .Layout_sidebar__2EpYu{-webkit-flex:0 1;flex:0 1;padding:1rem;background-color:#374243}.Layout_layout__vps_j .Layout_main__3OBIG{-webkit-flex:1 1;flex:1 1;background-color:#4e5e5f;padding:1rem 1rem 0 1rem;overflow:scroll}@media only screen and (min-width: 768px){.Layout_layout__vps_j{-webkit-flex-direction:row;flex-direction:row}.Layout_layout__vps_j .Layout_main__3OBIG,.Layout_layout__vps_j .Layout_sidebar__2EpYu{padding:2em 2rem 0 2rem}.Layout_layout__vps_j .Layout_main__3OBIG{-webkit-flex:4 1;flex:4 1}.Layout_layout__vps_j .Layout_sidebar__2EpYu{-webkit-flex:1 1;flex:1 1}}
.LoadingSpinner_spinnerContainer__1SoAP{width:100%;height:100%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;background-color:rgba(0,0,0,.1);z-index:999}.LoadingSpinner_spinnerContainer__1SoAP .LoadingSpinner_spinnerImage__3bnPn{height:10rem;width:10rem}.LoadingSpinner_spinnerContainerOverlay__2p-qU{position:absolute;width:100vw;height:100vh;top:0;left:0;background-color:rgba(0,0,0,.4)}
.Generate_form__Uwcxu{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}
.Card_card__35wN0{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-flex-direction:column;flex-direction:column;background-color:#374243;padding:1rem;height:10rem;border-radius:4px;box-shadow:0 4px 8px 0 rgba(0,0,0,.2);transition:.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);cursor:pointer}.Card_card__35wN0:hover{-webkit-transform:scale(1.02);transform:scale(1.02);box-shadow:0 10px 20px rgba(0,0,0,.12),0 4px 8px rgba(0,0,0,.06)}.Card_icon__146nY{-webkit-justify-content:center;justify-content:center;-webkit-flex-direction:row;flex-direction:row}.Card_icon__146nY .Card_title__Ip0in{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.Card_icon__146nY .Card_title__Ip0in>*{margin:5px}
.CardGrid_cardGrid__1AbQs{display:grid;grid-gap:1rem}@media(min-width: 768px){.CardGrid_cardGrid__1AbQs{grid-template-columns:repeat(2, 1fr)}}@media(min-width: 1024px){.CardGrid_cardGrid__1AbQs{grid-template-columns:repeat(3, 1fr)}}
.Recipes_recipes__2mzI1{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;height:100%}
.Register_heading__3LkS_{text-align:center}.Register_form__3-tVh{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

