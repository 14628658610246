@import '../../styles/Variables';

.sidebar {
  display: flex;
  flex-direction: column;

  .heading {
    margin: 0;
    color: $text-color;
    font-weight: 600;
    text-align: center;
  }

  .nav {
    padding-left: 0.5rem;
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;

    list-style: none;

    .navItem {
      display: inline-block;
      padding: 10px;
      font-size: 1.2rem;

      cursor: pointer;
      text-decoration: none;
      color: $text-color;
      width: 70%;

      &:hover {
        color: darken($text-color, 10%);
      }

      &:active {
        color: darken($text-color, 20%);
      }

      &:hover:after {
        width: 100%; /*change the width of the border like element*/
        background: $text-color;
      }

      // adds underline element
      &:after {
        content: '';
        display: block;
        margin: auto;
        height: 2px;
        width: 0px;
        background: transparent;
        transition: width 0.4s ease, background-color 0.4s ease;
      }

      &.active:after {
        content: '';
        display: block;
        margin: auto;
        height: 2px;
        width: 100%;
        background: $text-color;
      }
    }
  }
}

@media only screen and (min-width: $tablet-breakpoint) {
  .sidebar {
    .heading {
      height: 100px;
      margin-bottom: 2.5rem;
      text-align: left;
    }

    .nav {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
