@import '../../../styles/Variables';

.ingredientFieldWrapper {
  display: grid;
  grid-template-columns: 60px 125px 1fr 25px;
  gap: 10px;

  border: 2px solid $background-color;
  border-radius: 5px;

  margin: 10px;
  padding: 0 5px;
  max-width: 450px;
  height: 55px;
  width: 100%;

  .numberInputContainer,
  .unitSelect {
    display: flex;
    align-items: center;
  }

  .numberInputContainer {
    .numberInput {
      width: 100%;
      height: 38px;
      text-align: center;
    }
  }

  .unitSelect {
    max-width: 125px;

    & > div {
      width: 100%;
    }
  }

  .ingredientText {
    max-height: 45px;
    align-self: center;
    text-transform: capitalize;

    // limits to two rows, adding ... at end if needed
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .removeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
  }
}
