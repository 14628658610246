@import '../../styles/Variables';

.button {
  display: flex;
  align-items: center;
  max-width: 200px;
  max-height: 60px;
  margin: 1rem;
  padding: 1rem 1.5rem;
  color: $text-color;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 15px;
  background: none;
  border: 3px solid $text-color;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  & .label {
    width: 100%;
  }

  &:hover {
    border: 3px solid darken($text-color, 10%);
    color: darken($text-color, 65%);
    box-shadow: 0px 0px 4px $text-color;
    transform: scale(1.02);
  }

  &:active {
    border: 3px solid darken($text-color, 10%);
    color: darken($text-color, 65%);
    box-shadow: 0px 0px 6px $text-color;
  }

  &:disabled {
    background: grey;
    border: 3px solid gray;
    color: lightgray;
    cursor: not-allowed;
  }

  .rightIcon {
    margin-left: 5px;
  }
}
