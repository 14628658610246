@import '../JourneyStages.module.scss';

.inputArea {
  @include inputArea;

  flex-direction: column;
  justify-content: unset;

  & > .ingredientFields {
    display: grid;
    justify-items: center;
  }
}

.buttonArea {
  @include buttonArea;
}
