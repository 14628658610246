@import '../../styles/Variables';

.stepper {
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  max-width: 500px;

  .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $text-color;

    .stepText {
      cursor: default;
    }
  }

  .selected,
  .completed {
    background: $text-color;

    .stepText {
      // flex centers the tick
      display: flex;
      color: invert($text-color, 100%);
    }
  }
}
