@mixin inputArea {
  display: flex;
  justify-content: center;
  height: 75%;
  width: 100%;
}

@mixin buttonArea {
  height: 25%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  direction: rtl;

  & > * {
    justify-self: center;
    width: 100%;
  }
}
