@import '../../styles/Variables';

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .sidebar {
    flex: 0;
    padding: 1rem;
    background-color: $background-color;
  }

  .main {
    flex: 1;
    background-color: lighten($background-color, 10%);
    padding: 1rem 1rem 0 1rem;
    overflow: scroll;
  }
}

@media only screen and (min-width: $tablet-breakpoint) {
  .layout {
    flex-direction: row;

    .main,
    .sidebar {
      padding: 2em 2rem 0 2rem;
    }

    .main {
      flex: 4;
    }

    .sidebar {
      flex: 1;
    }
  }
}
