$mobile-breakpoint: 425px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$large-desktop-breakpoint: 1280px;

// outer-space-crayola : background-color
// platinum : text
// glaucous : primary
// gold-crayola : secondary

/* SCSS HEX */
$background-color: #374243ff;
$primary-color: #6a8cb4ff;
$secondary-color: #f9c784ff;
$text-color: #ebebebff;

/* SCSS Gradient */
$gradient-top: linear-gradient(
  0deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-right: linear-gradient(
  90deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-bottom: linear-gradient(
  180deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-left: linear-gradient(
  270deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-top-right: linear-gradient(
  45deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-bottom-right: linear-gradient(
  135deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-top-left: linear-gradient(
  225deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-bottom-left: linear-gradient(
  315deg,
  #374243ff,
  #ebebebff,
  #6a8cb4ff,
  #f9c784ff
);
$gradient-radial: radial-gradient(#374243ff, #ebebebff, #6a8cb4ff, #f9c784ff);
