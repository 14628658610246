@import '../../../styles/Variables';

.field {
  margin: 0.313rem;
  max-width: 300px;
  .name {
    display: block;
    transition: opacity 0.2s, visibility 0.2s;
  }

  .nameHidden {
    visibility: hidden;
    opacity: 0;
  }

  .inputWrapper {
    display: flex;
    position: relative;
    align-items: center;
    width: 18.75rem;

    .input {
      padding: 0.313rem;
      font-size: 1.2rem;
      width: 100%;
    }

    .rightIcon {
      position: absolute;
      right: 0;
      transform: translate(-0.5rem, 0);
      color: $background-color;
      cursor: pointer;

      & > * {
        display: flex;
      }

      &:hover {
        color: lighten($background-color, 15);
      }
    }
  }

  .errorText {
    color: #e88c29;
    font-style: italic;
  }
}
