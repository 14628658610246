@import '../../../styles/Variables';

.createRecipe {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: rgba($background-color, 0.25);
  border-radius: 5px;
}

.stageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}
