@import '../../styles/Variables';

.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  background-color: $background-color;
  padding: 1rem;
  height: 10rem;
  border-radius: 4px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  cursor: pointer;
}

.icon {
  justify-content: center;
  flex-direction: row;

  .title {
    display: flex;
    align-items: center;

    & > * {
      margin: 5px;
    }
  }
}
