@import '../../styles/Variables';

.cardGrid {
  display: grid;
  grid-gap: 1rem;
}

/* Screen for tablet 2 columns */
@media (min-width: $tablet-breakpoint) {
  .cardGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Screen for small desktop 3 columns */
@media (min-width: $desktop-breakpoint) {
  .cardGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
