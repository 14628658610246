.journeyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-bottom: 15px;

  .nameText {
    text-align: center;
    max-width: 85%;
    font-size: 1.3rem;
  }

  .childrenContainer {
    flex: 1;
    width: 100%;
    max-width: 750px;
    padding: 15px 0;
  }
}
